import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";

const HeaderNavigation: React.FC<any> = () => {
  const [openMenu, setOpenMenu] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuClick = (itemLabel: string) => {
    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      setOpenMenu(itemLabel === openMenu ? "" : itemLabel);
    };
  };

  const onItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setOpenMenu("");
    setMobileMenuOpen(false);
  };

  const toggleMobileOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="navbar navbar-default" role="navigation">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            onClick={toggleMobileOpen}
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#whrb-header-nav"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div
          className={"collapse navbar-collapse" + (mobileMenuOpen ? " in" : "")}
          id="whrb-header-nav"
        >
          <div className="ccm-custom-style-container ccm-custom-style-main-18 ccm-block-custom-template-whrb-nav">
            <ul className="nav navbar-nav">
              <li
                className={
                  "dropdown" + (openMenu === "departments" ? " open" : "")
                }
              >
                <a
                  href="#"
                  className="active dropdown dropdown-toggle"
                  onClick={handleMenuClick("departments")}
                >
                  Departments
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <Link to="/departments/classical" onClick={onItemClick}>
                      Classical Music
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/jazz" onClick={onItemClick}>
                      The Jazz Spectrum
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/sports" onClick={onItemClick}>
                      WHRB Sports
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/departments/recordhospital"
                      onClick={onItemClick}
                    >
                      Record Hospital
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/tds" onClick={onItemClick}>
                      The Darker Side
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/blues" onClick={onItemClick}>
                      The Blues Hangover
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/departments/hillbilly-at-harvard"
                      onClick={onItemClick}
                    >
                      Hillbilly at Harvard
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/news" onClick={onItemClick}>
                      WHRB News
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments/bsides" onClick={onItemClick}>
                      B-Sides
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  "dropdown" + (openMenu === "programming" ? " open" : "")
                }
              >
                <a
                  href="#"
                  className="dropdown dropdown-toggle"
                  onClick={handleMenuClick("programming")}
                >
                  Programming
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <Link to="/programming/program-guide" onClick={onItemClick}>
                      Program Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/programming/program-schedule"
                      onClick={onItemClick}
                    >
                      Program Schedule
                    </Link>
                  </li>
                  <li>
                    <Link to="/stream-archive" onClick={onItemClick}>
                      Stream Archive
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/programming/special-programs"
                      onClick={onItemClick}
                    >
                      Special Programs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/programming/whrb-covid-19-update"
                      onClick={onItemClick}
                    >
                      WHRB COVID-19 Updates
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact" onClick={onItemClick}>
                  Contact
                </Link>
              </li>
              <li className="visible-xs-block">
                <Link
                  to="/advertise"
                  onClick={onItemClick}
                  className="visible-xs-block"
                >
                  Advertise
                </Link>
              </li>
              <li className="dropdown visible-xs-block">
                <Link
                  to="/support"
                  onClick={onItemClick}
                  className="dropdown visible-xs-block dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Support
                </Link>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <Link to="/legacy" onClick={onItemClick}>
                      Making a Legacy Gift to WHRB
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about" onClick={onItemClick}>
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderNavigation;
